

























































































































import Vue from "vue";
import { mapState } from "vuex";
import { Toast } from "vant";
import moment from "moment";
import { dispatch, Product, Cart, CartProduct } from "@/store";

export default Vue.extend({
  data() {
    return {
      moment,
      loading: true,
      matchCoupons: [] as any,
      showVerify: false,
      discount: 0,
    };
  },
  computed: {
    ...mapState({ settings: "settings" }),
    ...mapState("orders", {
      pendingOrder: "pendingOrder",
    }),
    couponInfo(): string {
      if (this.discount) {
        return "-￥" + this.discount;
      }
      if (this.matchCoupons.length) {
        return this.matchCoupons.length + " 张可用";
      }
      return "暂无可用";
    },
    isVirtual(): boolean {
      return (
        this.pendingOrder.products.filter((i: Product) => i.product.is_virtual)
          .length === this.pendingOrder.products
      );
    },
    isCoinPay(): boolean {
      return this.pendingOrder.products[0]?.product.is_coin_pay;
    },
    total(): number {
      let total = 0;
      this.pendingOrder.products.map((item: Cart) => {
        total +=
          item.number * (this.isCoinPay ? item.sku.coin : item.sku.amount);
      });
      return total - this.discount;
    },
    totalEntity(): number {
      let total = 0;
      this.pendingOrder.products.map((item: Cart) => {
        console.log("item", item);
        if (!item.product.is_virtual)
          total +=
            item.number * (this.isCoinPay ? item.sku.coin : item.sku.amount);
      });
      return total;
    },
    shipping(): number {
      if (this.isCoinPay) return 0;
      const { is_open, amount, threshold } = this.settings.shipping;
      if (is_open && this.totalEntity && this.totalEntity < threshold) {
        return amount;
      } else if (!is_open && this.totalEntity && this.totalEntity < 100) {
        return 10;
      } else {
        return 0;
      }
    },
    isShippingOpen(): boolean {
      return this.settings.shipping.is_open;
    },
    shippingThreshold(): number {
      return this.settings.shipping.threshold;
    },
  },
  methods: {
    getMedia(product: Cart) {
      if (product.sku.medias && product.sku.medias[0]) {
        return product.sku.medias[0];
      } else {
        return product.product.main_medias[0];
      }
    },
    onSubmit() {
      this.showVerify = true;
    },
    onVerifyOpened() {
      const NoCaptcha = (window as any).NoCaptcha;

      const nc_token = [
        "FFFF0N00000000009B12",
        new Date().getTime(),
        Math.random(),
      ].join(":");
      const nc = NoCaptcha.init({
        renderTo: "#nc",
        appkey: "FFFF0N00000000009B12",
        scene: "nc_other_h5",
        token: nc_token,
        trans: { key1: "code200" },
        elementID: ["usernameID"],
        is_Opt: 0,
        language: "cn",
        timeout: 10000,
        retryTimes: 5,
        errorTimes: 5,
        inline: false,
        apimap: {
          // 'analyze': '//a.com/nocaptcha/analyze.jsonp',
          // 'uab_Url': '//aeu.alicdn.com/js/uac/909.js',
        },
        bannerHidden: false,
        initHidden: false,
        callback: (data: any) => {
          this.onPlaceOrder({
            session_id: data.csessionid,
            sign: data.sig,
            token: nc_token,
            scene: "nc_other_h5",
          });
          setTimeout(() => {
            this.showVerify = false;
          }, 150);
        },
      });
      NoCaptcha.setEnabled(true);
      nc.reset(); //请务必确保这里调用一次reset()方法
      //用于配置滑动验证的自定义文案。详细信息，请参见自定义文案与多语言文档。
      NoCaptcha.upLang("cn", {
        //加载状态提示。
        LOADING: "loading...",
        //等待滑动状态提示。
        SLIDER_LABEL: "请向右滑动验证下单",
        //验证通过状态提示。
        CHECK_Y: "验证通过",
        //验证失败触发拦截状态提示。
        ERROR_TITLE: "验证失败，请重新验证",
      });
    },
    onPlaceOrder(data: any) {
      Toast.allowMultiple(true);
      const toast = Toast.loading({
        message: "下单中...",
        duration: 0,
        forbidClick: true,
      });
      const productsShare = JSON.parse(
        localStorage.getItem("products-share") || "{}"
      );
      this.pendingOrder.products.map((item: CartProduct) => {
        item.share_code = productsShare[item.product_id] || "";
      });
      dispatch
        .ordersPost({
          ...this.pendingOrder,
          captcha: data,
        })
        .then((res) => {
          toast.clear();
          this.$router.replace(this.$paths.orders);
          setTimeout(() => {
            this.$router.push(this.$paths.orders + "/" + res.order.order_no);
          }, 100);
        })
        .catch(() => {
          toast.clear();
        });
    },
    onProductClick(product: Product) {
      this.$router.push(this.$paths.products + "/" + product.product.id);
    },
  },
  created() {
    if (this.pendingOrder.address_id) {
      dispatch.couponsMatchUserCoupon(this.pendingOrder).then((res) => {
        res.results.map((item: any) => {
          if (item.is_match) {
            this.matchCoupons.push(item);
          }
        });
      });
      if (this.pendingOrder.user_coupon_id) {
        dispatch
          .cartCalc({
            user_coupon_id: this.pendingOrder.user_coupon_id,
            products: this.pendingOrder.products,
          })
          .then((res) => {
            this.discount = res.discount_amount;
          });
      }
    } else {
      this.$router.replace(this.$paths.cart);
    }
  },
});
